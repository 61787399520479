import React, {useCallback, useEffect, useState} from 'react';
import {Card, CardBody} from 'reactstrap';
import { Multiselect } from 'react-widgets';
import {getBrands, getCategories, getContractTypes} from '../../actions/brandUpdateActions';

function SelectBrand({ title, brand: brandProp, getDataChild }) {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState([]);
    const [currentData, setDataCurrent ] = useState([]);
    const [startData, setStartData] = useState([]);

    const generationComponent = (name) => {
        switch (name) {
            case 'Parent Brand' :
                return getBrands();
            case 'Categories' :
                return getCategories()
            default: return getContractTypes()
        }
    }

    const {
        parent_brand = {},
        categories = [] ,
        contract_types = {}
         } = brandProp

    const handleData = useCallback(async () => {
        if(startData.length > 0) return
        const result = await generationComponent(title)
        setStartData(result);

        // console.log(result, 'res');

        if(title === 'Contract Types') {
            const flattenedData = [];
            result.forEach(item => {
                flattenedData.push(item.name);

                // eslint-disable-next-line no-unused-expressions
                item?.sub_types?.forEach(subType => {
                    flattenedData.push( `${subType.name} (${item.name})`,)
                });
            });

            setData(flattenedData);

            return
        }

        setData(() => (result.map(item => item?.name)));
    }, [ title ]);

    useEffect(() => {
        if(title === 'Parent Brand') {
            if(Object.keys(parent_brand).length > 0) {
                setDataCurrent([parent_brand?.name])
                return
            } setDataCurrent([])
        }

        if(title === 'Categories') {
            if(Object.keys(categories).length > 0) {
                setDataCurrent(categories.map(item => item.name))
                return;
            }
        }

        if(title === 'Contract Types') {
            if(Object.keys(contract_types).length > 0) {
                setDataCurrent(contract_types)
            }
        }
    }, [ title ]);

    const generationDataComponent = (name, result) => {
        switch (name) {
            case 'Parent Brand' :
                return startData.filter(item => item?.name === currentData[0])
            case 'Categories' :
                return result.filter(obj => {
                    if(currentData.includes(obj.name)) return obj
                });
            case 'Contract Types':
                const ar = []

                currentData.forEach((item => {
                    if(typeof item === "object") {
                        ar.push(item)
                    } else {
                        const newData = getContractTypesItem(item)
                        ar.push(newData)
                    }
                }));

                return ar

                // const lastUpdate = currentData.splice(-1);
                //
                // if(typeof lastUpdate[0] === "object") {
                //     return startData.filter(obj => {
                //         if(currentData.includes(obj.name)) return obj
                //     });
                // } else {
                //     const newData = getContractTypesItem(lastUpdate)
                //     return [...currentData, newData ]
                // }
            default: return []
        }
    }

    async function loadComponent() {
        const result = await generationComponent(title)
        setStartData(result);

        const parent = generationDataComponent(title, result)
        //
        // console.log(title, brandProp);
        // console.log(parent);

        const currentName = title === 'Parent Brand' ? 'parent_id' : title.toLowerCase()
        const currentValue = title === 'Parent Brand' ? (currentData[0]?.id || brandProp?.parent_brand?.id) : parent

        // setDataCurrent(parent)
        // console.log(title, { [currentName]: currentValue || [] })

        console.log([currentName], currentValue);


        getDataChild(({ [currentName]: currentValue || [] }))
    }

    useEffect(() => {
        if(!currentData.length > 0) {
                if(title === 'Categories' || title === 'Contract Types') {
                    if(title === 'Categories') {
                        getDataChild(({ categories: [] }))
                    } else {
                        console.log('contract_types')
                        getDataChild(({ 'contract types': [] }))
                    }
                  
                }
        } else {
            loadComponent()
        }
      
    },[ currentData, brandProp, title ]);

    useEffect(() => {
        if(title === 'Contract Types') handleData();

        if(!open && !data.length > 0) return

        if(open) {
            handleData();
        }
    }, [open]);

    const handleToggle =  useCallback(async () => {
        setOpen(prevOpen => !prevOpen);
    },[ open ]);

    const getContractTypesItem = (value) => {
        let returnData = {}
        const oldDataStart = []

        startData.forEach(item => {
            oldDataStart.push({
               id: item.id, name: item.name
            })

            // eslint-disable-next-line no-unused-expressions
            item?.sub_types?.forEach(subType => {
                oldDataStart.push(
                    {
                        id: subType.id,
                        name: subType.name
                    }
                )
            });
        })

        oldDataStart.forEach(item => {
            if(item?.name === value.split('(')[0].trim()) {
                returnData = {...item};
            }
        })

        return returnData
    }

    return (
        <Card>
            <CardBody>
                <div className="d-inline card-title">
                    {title}
                    {
                        title === 'Contract Types' &&
                        <span className="text-danger">*</span>
                    }
                </div>
                {
                    data &&
                    <Multiselect
                        value={currentData}
                        onToggle={handleToggle}
                        onChange={(value) =>
                            title === 'Parent Brand' ?
                                setDataCurrent(value.slice(-1)) :
                                setDataCurrent(value)
                    }
                        onSearch={title === 'Parent Brand' ? async (value) => await setData(await getBrands(value ? value : '')) : ''}
                        data={data}
                        filter={title === 'Parent Brand' ? "contains" : ''}
                        // itemComponent={title === 'Contract Types' ? renderItem : null}
                        textField='name'
                        disabled={[
                            "Communication",
                            "Insurances",
                            "Test Insurances",
                            "Utilities"
                        ]}
                    />
                }

                { title === 'Parent Brand' &&  <label className="mt-1">Type to search Brand</label> }

                { title === 'Categories' && <label className="mt-1">Type to search Category e.g. Electronics</label> }

                { title === 'Contract Types' && <label className="mt-1">Type to search Contract Type e.g. Insurances</label> }

            </CardBody>
        </Card>
    );
}

export default React.memo(SelectBrand);

