import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes, array, bool } from 'prop-types';
import { Form, Input, Button, Badge, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialCheckbox from '../../shared/components/material-checkbox/MaterialCheckbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SimpleLineIcon from 'react-simple-line-icons';
import BrandCombobox from '../../shared/components/searchable-field/BrandCombobox';
import DataTableProgressComponent from '../../shared/components/widget/DataTableProgressComponent';
import DataTableNoDataComponent from '../../shared/components/widget/DataTableNoDataComponent';
import DropdownList from 'react-widgets/lib/DropdownList';
import { createObjectCsvWriter } from 'csv-writer';
import debounce from 'lodash/debounce';
import Combobox from 'react-widgets/lib/Combobox';

import { exportFunction } from '../../actions/exportAction.js'
import 'react-widgets/dist/css/react-widgets.css';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import './Modal.css';
import './Transactions.css';
import TransactionShow from './TransactionShow';

import memoize from 'memoize-one';
import DataTable, { createTheme } from 'react-data-table-component';

import { checkAuth } from '../../actions/sessionActions';
import { deleteTransaction, getTransaction, getTransactions } from '../../actions/transactionActions';
import { getBanks, getBanksList } from '../../actions/bankActions';

import { SubmissionError } from 'redux-form'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Redux Store
import configureStore from '../../shared/redux/configureStore';
import { deleteReview } from '../../actions/reviewActions';
// Configuring Redux Store
const store = configureStore(window.initialState);

const columns = memoize((handleEditClick, handleShowClick, handleDeleteClick) => [
  {
    name: 'ID',
    selector: 'id',
    sortable: false,
    center: true,
    grow: 0
  },
  {
    name: 'Reference',
    selector: 'reference',
    sortable: false,
    grow: 2
  },
  {
    name: 'Brand',
    selector: "brand",
    sortable: true,
    // eslint-disable-next-line react/jsx-no-target-blank
    cell: row => <a href={`/cashbacks/edit/${row.offer_id}`} target={'_blank'}> {row.brand != null ? row.brand.name : '---'} </a>,
    grow: 1
  },
  {
    name: 'Customer',
    selector: 'customer_id',
    sortable: false,
    grow: 1
  },
  {
    name: 'Type',
    selector: 'type',
    sortable: true,
    right: false,
    grow: 1
  },
  {
    name: 'Booked at',
    selector: "booked_at",
    sortable: false,
    cell: row => <div>{row.booked_at != null ? row.booked_at.substring(0, 10) : ''}</div>,
    grow: 1
  },
  {
    name: 'Amount',
    selector: "amount",
    sortable: false,
    cell: row => <div className="text-right">{row.amount}</div>,
    grow: 1
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 1
  },
  {
    name: 'Notes',
    selector: 'notes',
    sortable: false,
    grow: 1
  },
  {
    name: 'Actions',
    sortable: false,
    left: true,
    cell: (row) => <div>
      <button className="btn btn-sm btn-light mr-2" onClick={handleEditClick} id={row.id}><i className="icon-pencil" onClick={handleEditClick} id={row.id}></i></button>
      <button className="btn btn-sm btn-light mr-2" onClick={handleDeleteClick} id={row.id}><i className="icon-trash" id={row.id}></i></button>
      <button className="btn btn-sm btn-light mr-2" onClick={handleShowClick} id={row.id}><i className="icon-book-open" onClick={handleShowClick} id={row.id}></i></button>
    </div>,
    ignoreRowClick: true
  }
]);


const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

class Transactions extends Component {

  constructor(props) {
    super(props);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleShowClick = this.handleShowClick.bind(this);
    this.csvFile = this.csvFile.bind(this);
    this.handleQueryChange = this.handleQueryChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleBankChange = this.handleBankChange.bind(this);
    this.handleBankFilterChange = debounce(this.handleBankFilterChange.bind(this), 300);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onSearchableInputChange = this.onSearchableInputChange.bind(this);
    var bankId = ((new URLSearchParams(window.location.search)).get("bank_id"));

    this.state = {
      data: [],
      loading: false,
      totalRows: 0,
      perPage: 10,
      sort: false,
      search: null,
      modalOpen: false,
      transaction: null,
      modalTitle: null,
      error: null,
      banks: [],
      selectedBank: bankId == null ? null : parseInt(bankId),
      showSearch: false
    };
  }

  componentDidMount(props) {
    this.setState({ loading: true });
    console.log('componentDidMount');
    console.log('---accounts')
    console.log(this.props.accounts);
    this.setState({ loading: false, data: [] });
    // const {getBanks} = this.props;
    // getBanks();
    getBanksList().then(({ items }) => this.setState(item => ({ ...item, banks: items })));

    if (this.state.selectedBank != null) {
      this.setState({ showSearch: true }, function () {
        this.fetchTransactions();
      });
    }
    /*
    const { perPage } = this.state;
    console.log(perPage);
    var params = {}
    params.per_page = perPage;
    store.dispatch(getTransactions(params)).then((result) => {
      this.setState({
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        loading: false,
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalTitle: 'Error!',
        modalOpen: true
      });
    });
    */


    /* JWT is stored in memory */
    /*
    const {session} = this.props
    if(typeof(session.jwt)=='undefined') {
      sessionStorage.removeItem('jwt');
      this.setState({session: null});
      this.props.history.push({pathname: '/login'});
      window.location.href="/login";
    }
    */
  }

  handleQueryChange(event) {
    console.log('handleQueryChange');
    console.log(event.target.value)
    var search = {}
    search['search[brand]'] = encodeURIComponent(event.target.value);
    this.setState({ search: search });
  }

  handleDeleteClick(event) {
    console.log('handleDeleteClick');
    console.log(this.state.selectedBank);
    if (window.confirm('Are you sure you want to delete this review?')) {
      store.dispatch(deleteTransaction(this.state.selectedBank, event.target.id)).then((result) => {
        if (typeof (result) != 'undefined') {
          var review = result.payload;
          window.location.reload();
        }
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

  handleFindTransactions = async (e) => {
    e.preventDefault();
    console.log('handleFindTransactions');
    console.log(this.state.search);
    if (this.state.search && this.state.search.toString().length > 1) {
      this.setState({ loading: true });
      var params = {}
      params.per_page = this.state.perPage;
      params.bank_id = this.state.selectedBank;
      if (this.state.sort) {
        Object.assign(params, this.state.sort)
      }
      if (this.state.search) {
        Object.assign(params, this.state.search)
      }
      store.dispatch(getTransactions(params)).then((result) => {
        this.setState({
          loading: false,
          data: result.payload.items,
          totalRows: result.payload.meta.total_items
        });
      }).catch((error) => {
        //throw new SubmissionError({_error:  error });
        this.setState({
          error: error,
          modalOpen: true
        });
      });
    }
  }

  handlePageChange = async page => {
    console.log('handlePageChange');
    this.setState({ loading: true });
    const { perPage } = this.state;
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getTransactions(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });

  }

  handlePerRowsChange = async (perPage, page) => {
    console.log('handlePerRowsChange');
    this.setState({ loading: true });
    var params = {}
    params.per_page = perPage;
    params.page = page;
    params.bank_id = this.state.selectedBank;
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getTransactions(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        perPage: perPage,
        totalRows: result.payload.meta.total_items
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  handleSort = async (column, sortDirection) => {
    console.log('handleSort');
    this.setState({ loading: true });
    var params = {}
    params.per_page = this.state.perPage;
    params.bank_id = this.state.selectedBank;
    var sort = {}
    sort['order[' + column.selector + ']'] = sortDirection;
    Object.assign(params, sort)
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getTransactions(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        sort: sort,
        totalRows: result.payload.meta.total_items
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  };

  handleEditClick(event) {
    console.log('handleEditClick');
    this.props.history.push({
      pathname: '/transactions/edit/' + event.target.id + '/' + this.state.selectedBank,
      search: "?" + new URLSearchParams({ bank_id: this.state.selectedBank }).toString()
    });
  }

  handleShowClick(event) {
    console.log('handleShowClick');
    store.dispatch(getTransaction({ id: event.target.id, bank_id: this.state.selectedBank })).then((result) => {
      if (typeof (result) != 'undefined') {
        console.log(result);
        var transaction = result.payload;
        this.setState({
          transaction: transaction,
          modalTitle: transaction.title
        });
        this.setState({ modalOpen: true });
      }
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  onSearchInputChange = (evt, field) => {
    console.log('---evt')
    console.log('evt: ', evt);
    console.log('field: ', field);
    const search = Object.assign({}, this.state.search);
    if (typeof (field) != 'undefined') {
      if (field == 'status') {
        if (evt == 'Pending') {
          var evt = {
            target: { name: 'status', value: 'pending', checked: true }
          }
        }
        else if (evt == 'Confirmed') {
          var evt = {
            target: { name: 'status', value: 'confirmed', checked: false }
          }
        }
        else if (evt == 'Declined') {
          var evt = {
            target: { name: 'status', value: 'declined', checked: false }
          }
        }
        else if (evt == 'Paid') {
          var evt = {
            target: { name: 'status', value: 'paid', checked: false }
          }
        }
        else {
          var evt = {
            target: { name: 'status', value: '', checked: null }
          }
        }
      }
      if (field == 'type') {
        if (evt == 'Deposit') {
          var evt = {
            target: { name: 'type', value: 'deposit', checked: true }
          }
        }
        else if (evt == 'Withdraw') {
          var evt = {
            target: { name: 'type', value: 'withdraw', checked: false }
          }
        }
        else {
          var evt = {
            target: { name: 'type', value: '', checked: null }
          }
        }
      }

      if (field === 'online') {
        var evt = {
          target: { name: 'online', value: evt === 'Online', checked: null }
        }
      }
    }
    if (evt.target.value != '' || evt.target.name === 'online') {
      search['search[' + evt.target.name + ']'] = encodeURIComponent(evt.target.value);
    }

    // else if(field === 'online') {
    //   search['search[online]'] = evt === 'Online';
    // }

    else if (evt.target.name == 'status' && evt.target.checked != null) {
      search['search[' + evt.target.name + ']'] = evt.target.value;
    }
    else if (evt.target.name == 'status' && evt.target.checked == null) {
      delete search['search[' + evt.target.name + ']'];
    }
    else if (evt.target.name == 'type' && evt.target.checked != null) {
      search['search[' + evt.target.name + ']'] = evt.target.value;
    }
    else if (evt.target.name == 'type' && evt.target.checked == null) {
      delete search['search[' + evt.target.name + ']'];
    }
    else {
      delete search['search[' + evt.target.name + ']'];
    }
    console.log('search: ', search)
    this.setState({ search });
  }

  getCurrentData() {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();

    return `transaction-${year}-${month}-${day}`;
  }

  async csvFile() {
    let rows = await exportFunction(this.state);


    if (rows) {
      let csvContent = "data:text/csv;charset=utf-8,";

      csvContent += rows

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.getCurrentData()}.csv`);
      document.body.appendChild(link);

      link.click();
    }
  }

  onSearchableInputChange(name, value) {
    console.log('onSearchableInputChange');
    const search = Object.assign({}, this.state.search);
    if (value != '') {
      search['search[' + name + ']'] = encodeURIComponent(value);
    }
    else if (name == 'active') {
      search['search[' + name + ']'] = value; // true/false
    }
    else {
      delete search['search[' + name + ']'];
    }
    this.setState({ search });
  };

  handleModalOpen() {
    this.setState({ modalOpen: true });
  }

  handleBankFilterChange = debounce((value) => {
    if(typeof value === 'object') {
      this.handleBankChange(value);
    } else {
      getBanksList({ 'search[name]': value }).then(({ items }) => {
        if (JSON.stringify(this.state.banks) !== JSON.stringify(items)) {
          this.setState({ banks: items });
        }
      });
    }
   
  }, 300);

  handleModalClose() {
    this.setState({ modalOpen: false })
  }

  handleBankChange(e) {
    console.log('---handleBankChange');
    if (e.id != '' && e.id > 0) {
      this.setState({ selectedBank: e.id, showSearch: true }, function () {
        this.fetchTransactions();
      });
      this.props.history.push({
        pathname: '/transactions/transactions/' + e.id,
        search: "?" + new URLSearchParams({ bank_id: e.id }).toString()
      });
    }
    else {
      this.setState({
        data: [],
        loading: false,
        totalRows: 0,
        perPage: 10,
        sort: false,
        search: null,
        showSearch: false,
        selectedBank: null
      });
    }
  }

  fetchTransactions() {
    var params = {}
    params.per_page = this.state.perPage;
    params.bank_id = this.state.selectedBank;
    var sort = {}
    if (this.state.sort) {
      Object.assign(params, this.state.sort)
    }
    if (this.state.search) {
      Object.assign(params, this.state.search)
    }
    store.dispatch(getTransactions(params)).then((result) => {
      this.setState({
        loading: false,
        data: result.payload.items,
        totalRows: result.payload.meta.total_items,
        sort: sort
      });
    }).catch((error) => {
      //throw new SubmissionError({_error:  error });
      this.setState({
        error: error,
        modalOpen: true
      });
    });
  }

  render() {
    console.log('---render');

    const session = this.props.session
    if (typeof (session.forceLogout) != "undefined" && session.forceLogout == 1) {
      this.setState({ session: null });
      window.location.href = "/login";
    }

    const { loading, data, totalRows } = this.state
    // const {banks} = this.props;

    const handleChange = (state) => {
      // You can use setState or dispatch with something like Redux so we can use the retrieved data
      console.log('Selected Rows: ', state.selectedRows);
    }

    const banksArr = this.state.banks.map(bank => ({ id: bank.id, name: bank.name }));


    return (
      <div className="animated fadeIn mt-5">
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          id="modal"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.modalOpen}>
            <div className="material-modal-content">
              <h4 id="transition-modal-title">{this.state.modalTitle} <i className="cil-x cursor-pointer float-right" onClick={this.handleModalClose}></i></h4>
              <div id="transition-modal-description">
                {this.state.error != null
                  ? <div>{this.state.error.toString()}</div>
                  : <TransactionShow transaction={this.state.transaction} />
                }
              </div>
            </div>
          </Fade>
        </Modal>
        <Row>
          <Col xs="12" lg="2">
            {/* <DropdownList filter={this.handleBankFilterChange}
                            placeholder="Choose Bank"
                            data={[{id:'0', name: ''}].concat(banksArr)}
                            valueField="id"
                            textField="name"
                            onChange={this.handleBankChange}
                            className="mb-4"
              /> */}

            <Combobox
              placeholder="Choose Bank"
              data={[].concat(banksArr)}
              valueField="id"
              textField="name"
              defaultValue={{id: this.state.selectedBank!=null ? this.state.selectedBank: 0, name: (this.state.selectedBank!=null && banksArr?.length>0) ? banksArr?.find(b => b.id == this.state.selectedBank)?.name : ''}}
              type="select"
              filter={false}
              asterisk="*"
               className="mb-4"
              onChange={this.handleBankFilterChange}
            />
          </Col>

          {
            this.state?.selectedBank &&
            <Col style={{
              padding: 0
            }} xs="12" lg="2">
              <button className={'rw-input'} onClick={this.csvFile}
                style={{
                  borderRadius: '4px',
                  height: '34px'
                }}
              >
                Export
              </button>
            </Col>
          }
        </Row>
        <Row>
          <Col xs="12" lg="10">
            {/*<div className="d-flex mb-4 justify-content-end">
              <Link to="/transactions/new">
                <Button color="secondary" className="bg-white text-dark" outline>
                  <i className="cil-plus text-dark"></i>
                </Button>
              </Link>
            </div>
            */}
          </Col>
          <Col xs="12" lg="12">
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center justify-content-between">
                  <div><i className="fa fa-align-justify"></i> Transactions list</div>
                  {this.state.showSearch &&
                    <div>
                      <Form className="form-inline float-right text-right items-end" onSubmit={this.handleFindTransactions}>
                        <div className="mr-2">
                          <label htmlFor="start_date">
                            Time Period From
                          </label>
                          <Input type="date" name="start_date"
                            id={'start_date'}
                            className="text-left"
                            placeholder="Time Period From"
                            onChange={(e) => this.onSearchInputChange(e, 'start_date')}
                          />
                        </div>
                        <div className="mr-2">
                          <label htmlFor="start_date">
                            Time Period To
                          </label>
                          <Input type="date" name="end_date"
                            className="text-left"
                            onChange={(e) => this.onSearchInputChange(e, 'end_date')}
                          />
                        </div>
                        <Input type="search" name="customer" className="mr-2 text-left" placeholder="Customer" onChange={this.onSearchInputChange} />
                        <BrandCombobox onChange={this.onSearchableInputChange} className="w-200" />
                        <Input type="search" name="reference" className="mr-2 text-left" placeholder="Reference" onChange={this.onSearchInputChange} />
                        <DropdownList
                          name="type"
                          onChange={(e) => this.onSearchInputChange(e, 'type')}
                          data={['All types', 'Deposit', 'Withdraw']}
                          placeholder="Type"
                          className="status-dropdown text-left mr-2"
                        />

                        <DropdownList
                          name="status"
                          onChange={(e) => this.onSearchInputChange(e, 'online')}
                          data={['Online', 'Offline']}
                          placeholder="Online/Offline"
                          className="status-dropdown text-left mr-2"
                        />
                        <DropdownList
                          name="status"
                          onChange={(e) => this.onSearchInputChange(e, 'status')}
                          data={['All statuses', 'Pending', 'Confirmed', 'Declined', 'Paid']}
                          placeholder="Status"
                          className="status-dropdown text-left"
                        />
                        <a onClick={(e) => this.handleFindTransactions(e)} className="search-btn ml-1 cursor-pointer btn btn-outline-secondary"><i className="icon-magnifier"></i></a>
                      </Form>
                    </div>
                  }
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  noHeader={false}
                  columns={columns(this.handleEditClick, this.handleShowClick, this.handleDeleteClick)}
                  onSelectedRowsChange={this.updateState}
                  data={data}
                  theme="solarized"
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[10, 15, 30, 50]}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  onSort={this.handleSort}
                  sortServer
                  selectableRows
                  selectableRowsComponent={MaterialCheckbox}
                  selectableRowsComponentProps={selectProps}
                  onSelectedRowsChange={handleChange}
                  progressComponent={<DataTableProgressComponent />}
                  noDataComponent={<DataTableNoDataComponent />}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

Transactions.propTypes = {
  data: PropTypes.array,
  banks: PropTypes.array,
  session: PropTypes.any
}

function mapStateToProps(state) {
  console.log('STATE')
  console.log(state);
  return {
    //transactions: state.transactions,
    data: (typeof (state.transactions) !== 'undefined' && typeof (state.transactions.result) !== 'undefined') ? state.transactions.result.items : [],
    banks: (typeof (state.banks) !== 'undefined' && typeof (state.banks.result) !== 'undefined') ? state.banks.result.items : [],
    session: state.session
  }
};

const mapDispatchToProps = dispatch => bindActionCreators({ getTransaction, getTransactions, getBanks, checkAuth }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transactions)